import styled from 'styled-components'

export const StyledFooter = styled.footer`
    background-color: #C8EAD7;
    margin: 25px 0;
    text-align: center;
    padding-bottom: 10px;
    position: relative;
    bottom: 0;
    top: 100px;
`

export const Name = styled.h5`
    padding-top: 25px;
`

export const Info = styled.p`
    color: #995205;
    margin: 15px 0;
`

export const Copyright = styled.p`
    font-size: 14px;
    margin-bottom: 0;
`