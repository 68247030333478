import React, { useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { client, linkResolver } from '../prismic-configuration'
import { Spinner } from '../components'
import NotFound from './NotFound'
import { 
    FaqPageContainer, 
    FaqContainer,
    ArrowContainer,
    Arrow,
    TextContainer,
} from '../styles/Faq.js'

const Faq = ({ setIsHome }) => {
    const [doc, setDocData] = useState(null)
    const [notFound, toggleNotFound] = useState(false)

    useEffect(() => {
        setIsHome(false)
        const fetchData = async () => {
            const result = await client.getByUID('faq', 'faq')

            if (result) {
                return setDocData(result)
            } else {
                console.warn('Page document not found. Make sure it exists in your Prismic repository')
                toggleNotFound(true)
            }
        }
        fetchData()
    }, [])

    if (doc) {
        return (
            <div className="page">
                <h1>{RichText.asText(doc.data.title)}</h1>
                <FaqPageContainer>
                    <FaqContainer>
                        <ArrowContainer>
                            <Arrow/>
                        </ArrowContainer>
                        <TextContainer>
                            <RichText render={doc.data.question1} linkResolver={linkResolver} />
                            <RichText render={doc.data.answer1} linkResolver={linkResolver} />
                        </TextContainer>
                    </FaqContainer>
                    <FaqContainer>
                        <ArrowContainer>
                            <Arrow/>
                        </ArrowContainer>
                        <TextContainer>
                            <RichText render={doc.data.question2} linkResolver={linkResolver} />
                            <RichText render={doc.data.answer2} linkResolver={linkResolver} />
                        </TextContainer>
                    </FaqContainer>
                    <FaqContainer>
                        <ArrowContainer>
                            <Arrow/>
                        </ArrowContainer>
                        <TextContainer>
                            <RichText render={doc.data.question3} linkResolver={linkResolver} />
                            <RichText render={doc.data.answer3} linkResolver={linkResolver} />
                        </TextContainer>
                    </FaqContainer>
                    <FaqContainer>
                        <ArrowContainer>
                            <Arrow/>
                        </ArrowContainer>
                        <TextContainer>
                            <RichText render={doc.data.question4} linkResolver={linkResolver} />
                            <RichText render={doc.data.answer4} linkResolver={linkResolver} />
                        </TextContainer>
                    </FaqContainer>
                    <FaqContainer>
                        <ArrowContainer>
                            <Arrow/>
                        </ArrowContainer>
                        <TextContainer>
                            <RichText render={doc.data.question5} linkResolver={linkResolver} />
                            <RichText render={doc.data.answer5} linkResolver={linkResolver} />
                        </TextContainer>
                    </FaqContainer>
                    <FaqContainer>
                        <ArrowContainer>
                            <Arrow/>
                        </ArrowContainer>
                        <TextContainer>
                            <RichText render={doc.data.question6} linkResolver={linkResolver} />
                            <RichText render={doc.data.answer6} linkResolver={linkResolver} />
                        </TextContainer>
                    </FaqContainer>
                    <FaqContainer>
                        <ArrowContainer>
                            <Arrow/>
                        </ArrowContainer>
                        <TextContainer>
                            <RichText render={doc.data.question7} linkResolver={linkResolver} />
                            <RichText render={doc.data.answer7} linkResolver={linkResolver} />
                        </TextContainer>
                    </FaqContainer>
                    <FaqContainer>
                        <ArrowContainer>
                            <Arrow/>
                        </ArrowContainer>
                        <TextContainer>
                            <RichText render={doc.data.question8} linkResolver={linkResolver} />
                            <RichText render={doc.data.answer8} linkResolver={linkResolver} />
                        </TextContainer>
                    </FaqContainer>
                </FaqPageContainer>
            </div>
        )
    } else if (notFound) {
        return <NotFound />
    } else {
        return (
            <div className="page">
                <Spinner />
            </div>
        )
    }
}

export default Faq