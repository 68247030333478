import styled, { css } from 'styled-components'

export const Content = styled.main`
    margin: 0 10% 50px 10%;
`

export const Profile = styled.section`
    display: flex;
    margin-bottom: 25px;
    max-width: 1150px;
    white-space: pre-wrap;

    ${props => props.reverse && css`
        flex-direction: row-reverse;
    `}

    @media screen and (max-width: 1024px){
        flex-direction: column;
        align-items: center;
    }

    @media screen and (max-width: 479px) {
        white-space: normal;
    }
`

export const ImgColumn = styled.div`
    margin: 0 15px;
    
    img{
        max-width: 350px;
        padding: 10px;
        border: 1px solid lightgrey;
        margin-bottom: 10px;
    }

    @media screen and (max-width: 1024px){
        max-width: 250px;
        img {
            max-width: 250px;
        }
    }
`

export const TextColumn = styled.div`
    margin: 0 15px;
`

export const Name = styled.h2`
    color: #4a825b;
    margin-bottom: 10px;
    font-style: italic;
`