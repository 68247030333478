import React from 'react'
import { StyledFooter, Name, Info, Copyright } from '../styles/Footer'

const Footer = () => {
    return (
        <StyledFooter>
            <Name>Complete Health and Healing</Name>
            <Info>
                <a href='https://goo.gl/maps/QToUedHyjigZqTuh8' target='_blank'>157 Clawson St. Staten Island, NY 10306</a>
                <br/>P: <a href='tel:7189875454'>(718) 987-5454</a> || F: (718) 987-0747
                <br/><a href='mailto:Healthnhealing4you@gmail.com'>Healthnhealing4you@gmail.com</a>
            </Info>
            <Copyright>© 2021 Complete Health and Healing</Copyright>
        </StyledFooter>
    )
}

export default Footer