import styled from 'styled-components'

export const MissionContainer = styled.div`
    margin: 0 20%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    height: 90vh;

    @media screen and (max-width: 479px) {
        height: 50%;
        margin: 0 15% 75px 15%;
        height: 90vh;
    }
`;

export const Heading = styled.h1`
    margin-bottom: -10px;

    @media screen and (max-width: 479px) {
        margin-bottom: 0;
    }
`;

export const Text = styled.p`
    font-size: 18px;
`;

export const MissionTextContainer = styled.div`
    text-indent: 10%;
    text-align: left;
    margin: 25px 0;
`;

export const WavyDivider = styled.img`
    height: 100px;

    @media screen and (max-width: 1024px) {
        margin-bottom: 25px;
    }

    @media screen and (max-width: 479px) {
        height: 75px;
        margin-bottom: 0;
    }
`