import React, { useEffect, useState } from 'react'
import { client } from '../prismic-configuration'
import NotFound from './NotFound'
import { Heading, AllServices } from '../styles/Services.js'
import { Spinner } from '../components'
import ServicesSection from '../components/ServicesSection'

const Services = ({ setIsHome }) => {
    const [doc, setDocData] = useState(null)
    const [notFound, toggleNotFound] = useState(false)
    const services = [
        'applied-kinesiology',
        'diagnostic-testing',
        'energy-healing',
        'holistic-nutrition'
    ]

    const renderServices = () => doc.map(service => <ServicesSection key={service.id} doc={service} />)

    useEffect(() => {
        setIsHome(false)
        const fetchData = async (service) => {
            const result = await client.getByUID('services', service)
            if (result) {
                return Promise.resolve(result)
            } else {
                console.warn('Page document not found. Make sure it exists in your Prismic repository')
                toggleNotFound(true)
            }
        }
        Promise.all(services.map(service => fetchData(service))).then(setDocData)
    }, [])

    if (doc) {
        return (
        <div className="page">
            <Heading>Services</Heading>
            <AllServices>
                {renderServices()}
            </AllServices>
        </div>
        )
    } else if (notFound) {
        return <NotFound />
    } else {
        return (
            <div className="page">
                <Spinner />
            </div>
        )
    }
}

export default Services