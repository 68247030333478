import React from 'react'
import { LogoContainer, StyledLogo } from '../styles/Logo'
import { useHistory } from 'react-router-dom'

export default function Logo() {
    const history = useHistory();
    const handleClick = () => history.push('/')

    return (
        <LogoContainer>
            <StyledLogo onClick={handleClick} src='/assets/Logo.png' alt='complete health and healing logo' />
        </LogoContainer>
    )
}
