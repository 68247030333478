import styled from 'styled-components'
import { Button } from '../components'

export const ContactPageContainer = styled.main`
    display: flex;
    flex-flow: column;
    margin: 0 20% 100px 20%;
    align-self: stretch !important;

    @media screen and (max-width: 768px) {
        margin: 0 15% 75px 15%;
    }
    
    @media screen and (max-width: 479px){
        display: flex;
        flex-flow: row wrap;
    }
`;

export const ContactPageInfoContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-start !important;
    width: 100%;

    @media screen and (max-width: 479px) {
        display: flex;
        flex-flow: row wrap;
        height: 100%;
    }
`;

export const HoursContainer = styled.div`
    padding: 0 5% 5% 5%; 

    @media screen and (max-width: 479px) {
        padding: 5%;
        width: 100%;
    }
`;

export const LeftContainer= styled.div`
    padding: 0 5% 5% 5%;

    @media screen and (max-width: 479px) {
        padding: 5%;
        width: 100%;
    }
`;

export const ContactFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 3%;

    @media screen and (max-width: 479px) {
        width: 100%;
    }
`;

export const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 70%;
    margin-top: 2%;

    @media screen and (max-width: 479px) {
        width: 100%;
    }
`;

export const Label = styled.div`
    text-align: left;
    color: #4a825b;
    font-weight: bold;
`;

export const ErrorMessage = styled.div`
    font-size: 12px;
    color: #a77035;
`;

export const Input = styled.input`
    margin: 1% 0 2% 0;
`;

export const TextArea = styled.textarea`
    margin: 1% 0 2% 0;
    height: 150px;
`;

export const SubmitButton = styled(Button)`
    width: 145px;
    align-self: center;
    margin: 2% 0 5% 0;
`;

export const AppointmentsFeesInfoBox = styled.div`
    align-self: center;
    font-weight: bold;
    width: 450px;
    padding: 30px;
    background: #a3dcbb;

    @media screen and (max-width: 479px) {
        padding: 5%;
        width: 100%;
    }
`;

export const AppointmentsFeesInfo = styled.p`
    margin-bottom: 0 !important;
`;