import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components'

export const StyledNavbar = styled(Navbar)`
    background-color: #C8EAD7 !important;
    position: fixed;
    z-index: 10;
    width: 100vw;
    top: ${({ ishome }) => ishome ? '0' : '75px' };

    @media screen and (max-width: 479px){
        top: ${({ ishome }) => ishome ? '0' : '54px' };
    }
    @media screen and (max-width: 320px){
        top: ${({ ishome }) => ishome ? '0' : '40px' };
    }
`;

export const StyledNav = styled(Nav)`
    display: flex !important;
    justify-content: space-around !important;
    width: 100vw !important;
`;

export const StyledDropdown = styled(NavDropdown)`
    @media screen and (max-width: 991px){
        width: 35% !important;
        a:hover {
            border-bottom: 0;
        }
    }

    @media screen and (max-width: 479px){
        width: 100% !important;
        padding-right: 32px !important;
    }
`;

export const StyledDropdownLink = styled(HashLink)`
    border-bottom: none !important;

    &:active, &.active {
        background-color: #c8ead7 !important;
    }

    @media screen and (max-width: 991px){
        &.active {
            background-color: #a3dcbb !important;
        }
    }
`;

export const StyledExternalLink = styled.a`
    border-bottom: none !important;

    &:active, &.active {
        background-color: #c8ead7 !important;
    }

    @media screen and (max-width: 991px){
        &.active {
            background-color: #a3dcbb !important;
        }
    }
`;