import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../prismic-configuration'
import {
    ServiceContainer,
    ImageContainer,
    StyledImage,
    ServicesTextContainer,
    ServicesText,
    SubHeading
} from '../styles/Services.js'
import { Button } from '../components'
import { useHistory } from 'react-router-dom'

const ServicesSection = ({ doc }) => {
    const history = useHistory()
    const handleClick = () => history.push('/contact-us')
    return (
        <ServiceContainer id={doc.uid}>
            <SubHeading>{RichText.asText(doc.data.service_name)}</SubHeading>
            <ImageContainer>
                <StyledImage src={doc.data.image.url} alt={doc.data.image.alt} />
            </ImageContainer>
            <ServicesTextContainer>
                <h3>{RichText.asText(doc.data.title)}</h3>
                <ServicesText>
                    <RichText render={doc.data.content} linkResolver={linkResolver} />
                </ServicesText>
                <Button onClick={handleClick}>Contact Us</Button>
            </ServicesTextContainer>
        </ServiceContainer>
    )
}

export default ServicesSection