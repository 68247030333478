import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Link } from 'react-router-dom'
import {
    StyledNavbar,
    StyledNav,
    StyledDropdown,
    StyledDropdownLink,
    StyledExternalLink
} from '../styles/Navbar.js'

export default function MyNavbar({ scrollWidthOffset, isHome }) {
    const handleScroll = (element) => scrollWidthOffset(element, window.screen.width)

    return (
        <StyledNavbar ishome={isHome} collapseOnSelect expand="lg">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <StyledNav>
                    <Nav.Link eventKey="1" as={Link} to="/">Home</Nav.Link>
                    <StyledDropdown title="About Us" id="collapsible-about-dropdown">
                        <NavDropdown.Item eventKey="2" as={StyledDropdownLink} to="/mission">Mission</NavDropdown.Item>
                        <NavDropdown.Item eventKey="3" as={StyledDropdownLink} to="/our-team">Our Team</NavDropdown.Item>
                    </StyledDropdown>
                    <StyledDropdown title="Services" id="collapsible-services-dropdown">
                        <NavDropdown.Item eventKey="4" as={StyledDropdownLink} scroll={handleScroll} to="/services#applied-kinesiology">Applied Kinesiology</NavDropdown.Item>
                        <NavDropdown.Item eventKey="5" as={StyledDropdownLink} scroll={handleScroll} to="/services#diagnostic-testing">Diagnostic Testing</NavDropdown.Item>
                        <NavDropdown.Item eventKey="6" as={StyledDropdownLink} scroll={handleScroll} to="/services#energy-healing">Energy Healing</NavDropdown.Item>
                        <NavDropdown.Item eventKey="7" as={StyledDropdownLink} scroll={handleScroll} to="/services#holistic-nutrition">Holistic Nutrition</NavDropdown.Item>
                    </StyledDropdown>
                    <StyledDropdown title="Appointments & Fees" id="collapsible-appointments-dropdown">
                        <NavDropdown.Item eventKey="8" as={StyledDropdownLink} to="/appointments-fees">Information</NavDropdown.Item>
                        <StyledExternalLink eventKey="9" className="dropdown-item" href="https://complete-health-and-healing.cdn.prismic.io/complete-health-and-healing/1b1e6b74-adf0-4e9f-97da-a8048e8b60b3_Questionnaire.docx" target="_blank" rel="noreferrer">Questionnaire</StyledExternalLink>
                    </StyledDropdown>
                    <Nav.Link eventKey="10" as={Link} to="/faq">FAQ</Nav.Link>
                    <Nav.Link eventKey="11" as={Link} to="/contact-us">Contact Us</Nav.Link>
                </StyledNav>
            </Navbar.Collapse>
        </StyledNavbar>
    )
}
