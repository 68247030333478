import styled from 'styled-components'

export const Content = styled.main`
    margin: 0 20% 50px 20%;

    @media screen and (max-width: 1024px){
        margin: 0 15% 25px 15%;
    }

    @media screen and (max-width: 479px) {
        margin: 0 10% 25px 10%;
    }
`

export const Section = styled.section`
    margin-bottom: 50px;
`

export const SectionTitle = styled.h2`
    text-align: center;
    font-style: italic;
    font-size: 28px;
    margin-bottom: 25px;
`

export const IndentedText = styled.div`
    margin-left: 10%;
`

export const CovidText = styled.div`
    color: #c00000;
    margin: 25px 0;
    padding: 10px;
    padding-bottom: 0;
    border: 1px solid #c00000;

    .underline {
        border-color: #c00000;
    }
`