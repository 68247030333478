import React, { useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { client, linkResolver } from '../prismic-configuration'
import NotFound from './NotFound'
import { MissionContainer, Heading, MissionTextContainer, Text, WavyDivider } from '../styles/Mission.js'
import { Button, Spinner } from '../components'
import { useHistory } from 'react-router-dom'

const Mission = ({ setIsHome }) => {
    const [doc, setDocData] = useState(null)
    const [notFound, toggleNotFound] = useState(false)
    const history = useHistory()
    const handleClick = () => history.push('/our-team')

    useEffect(() => {
        setIsHome(false)
        const fetchData = async () => {
            const result = await client.getByUID('mission', 'mission')

            if (result) {
                return setDocData(result)
            } else {
                console.warn('Page document not found. Make sure it exists in your Prismic repository')
                toggleNotFound(true)
            }
        }
        fetchData()
    }, [])

    if (doc) {
        return (
            <div className="page">
                <MissionContainer>
                    <Heading>{RichText.asText(doc.data.title)}</Heading>
                    <WavyDivider src='/assets/green-divider.svg' alt='green divider' />
                    <Text>
                        <RichText render={doc.data.subtitle} linkResolver={linkResolver} />
                    </Text>
                    <MissionTextContainer>
                        <Text>
                            <RichText render={doc.data.content} linkResolver={linkResolver} />
                        </Text>
                    </MissionTextContainer>
                    <Button onClick={handleClick}>Meet Our Team</Button>
                </MissionContainer>
            </div>
        )
    } else if (notFound) {
        return <NotFound />
    } else {
        return (
            <div className="page">
                <Spinner />
            </div>
        )
    }
}

export default Mission