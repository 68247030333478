import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link';
import { FaLeaf } from "react-icons/fa"

export const ImageContainer = styled.main`
    width: 100%;
    height: 100%;
    position: relative;
    top: 56px;
`;

export const Image = styled.img`
    width: 100%;
    position: fixed;

    @media screen and (max-width: 1024px){
        display: none
    }
`;

export const ImageIpad = styled.img`
    @media screen and (min-width: 1025px){
        display: none;
    }

    @media screen and (max-width: 1024px){
        width: 100%;
        position: fixed;
    }
`;

export const ImageMobile = styled.img`
    @media screen and (min-width: 480px){
        display: none;
    }

    @media screen and (max-width: 479px){
        width: 100%;
        position: fixed;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 5%;
    width: 50%;

    @media screen and (max-width: 1024px){
        right: 0;
        width: 100%;
    }

    @media screen and (max-width: 479px){
        right: 0;
        width: 100%;
    }
`;

export const Title = styled.h1`
    color: #379260;
    text-align: center;
    margin: 22% 0 2% 0;
    font-size: 48px;

    @media screen and (max-width: 1024px){
        font-size: 50px;
    }

    @media screen and (max-width: 479px){
        font-size: 32px;
    }
`;

export const Subtitle = styled.h4`
    text-align: center;
    font-style: italic;
    font-size: 30px;
    color: #985c1b;

    @media screen and (max-width: 1024px){
        font-size: 36px;
    }

    @media screen and (max-width: 479px){
        font-size: 18px;
    }
`;

export const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 36px;

    @media screen and (max-width: 1024px){
        margin: 72px 0;
    }
`;

export const Leaf = styled(FaLeaf)`
    color: #379260;
    margin-right: 5px;
`;

export const ServicesLinks = styled(HashLink)`
    color: #000000 !important;
    font-size: 28px;

    &:hover {
        border-bottom: 0;
    }

    @media screen and (max-width: 1024px){
        font-size: 36px;
    }

    @media screen and (max-width: 479px){
        font-size: 24px;
    }
`;

export const LinkStyle = styled.span`
    &:hover {
        border-bottom: 2px solid black;
    }
`;

export const Contact = styled.p`
    text-align: center;
    color: #995205;

    @media screen and (max-width: 1024px){
        font-size: 24px;
    }

    @media screen and (max-width: 479px){
        font-size: 14px;
    }
`;