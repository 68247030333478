import React, { useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { client, linkResolver } from '../prismic-configuration'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import emailjs from 'emailjs-com'
import{ init } from 'emailjs-com'
import NotFound from './NotFound'
import { 
    ContactPageContainer,
    ContactPageInfoContainer,
    HoursContainer,
    LeftContainer,
    ContactFormContainer,
    ContactForm,
    Label,
    ErrorMessage,
    Input,
    TextArea,
    SubmitButton,
    AppointmentsFeesInfoBox,
    AppointmentsFeesInfo,
} from '../styles/ContactUs.js'
import { Spinner } from '../components'
init(`${process.env.REACT_APP_USER_ID}`)

const ContactUs = ({ setIsHome }) => {
    const [doc, setDocData] = useState(null);
    const [notFound, toggleNotFound] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const onSubmit = (data, e) => {
        alert(`Thanks for your message ${data.name}.`)
        const templateId = `${process.env.REACT_APP_TEMPLATE_ID}`
        const serviceId = `${process.env.REACT_APP_SERVICE_ID}`
        sendFeedback(serviceId, templateId, { from_name: data.name, message: data.message, reply_to: data.email });
        e.target.reset();
    };
    const sendFeedback = (serviceId, templateId, variables) => {
        emailjs.send(
            serviceId, templateId, variables
        ).then(result => {
            console.log("Email successfully sent!")
        })
        .catch(error => console.error("There has been an error.  Here are some thoughts on the error that occured", error))
    };

    useEffect(() => {
        setIsHome(false)
        const fetchData = async () => {
            const result = await client.getByUID('contact_us', 'contact-us')

            if (result) {
                return setDocData(result)
            } else {
                console.warn('Page document not found. Make sure it exists in your Prismic repository')
                toggleNotFound(true)
            }
        }
        fetchData()
    }, [])

    if (doc) {
        return (
            <div className="page">
                <h1>{RichText.asText(doc.data.title)}</h1>
                <ContactPageContainer>
                    <ContactPageInfoContainer>
                        <LeftContainer>
                            <h5>{RichText.asText(doc.data.address_title)}</h5>
                                <p><a href='https://goo.gl/maps/QToUedHyjigZqTuh8' target='_blank'>157 Clawson St. <br/>Staten Island, NY 10306</a></p>
                            <h5>{RichText.asText(doc.data.phone_title)}</h5>
                                <p><a href='tel:7189875454'>(718) 987-5454</a></p>
                            <h5>{RichText.asText(doc.data.fax_title)}</h5>
                                <p style={{color: '#995205'}}>(718) 987-0747</p>
                            <h5>{RichText.asText(doc.data.email_title)}</h5>
                                <a href='mailto:Healthnhealing4you@gmail.com'>Healthnhealing4you@gmail.com</a>
                        </LeftContainer>
                        <HoursContainer>
                            <h5>{RichText.asText(doc.data.hours_title)}</h5>
                                <RichText render={doc.data.hours_text1} linkResolver={linkResolver} />
                                <RichText render={doc.data.hours_text2} linkResolver={linkResolver} />
                                <RichText render={doc.data.hours_text3} linkResolver={linkResolver} />
                                <RichText render={doc.data.hours_text4} linkResolver={linkResolver} />
                                <RichText render={doc.data.hours_text5} linkResolver={linkResolver} />
                        </HoursContainer>
                    </ContactPageInfoContainer>
                    <ContactFormContainer>
                        <h5>{RichText.asText(doc.data.contact_form_title)}</h5>
                        <RichText render={doc.data.contact_form_subtitle} linkResolver={linkResolver} />
                        <ContactForm onSubmit={handleSubmit(onSubmit)}>
                            <Label>Your Name</Label>
                            <Input
                                name="name" 
                                ref={
                                    register({
                                        required: "Please enter your name",
                                        maxLength: {
                                            value: 20,
                                            message: "Please enter a name with fewer than 20 characters"
                                        }
                                    })
                                }
                            />
                            <ErrorMessage>{errors.name && errors.name.message}<br/></ErrorMessage>
                            <Label>Your Email</Label>
                            <Input
                                name="email" 
                                ref={
                                    register({
                                        required: "Please enter an email",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address"
                                        }
                                    })
                                }
                            />
                            <ErrorMessage>{errors.email && errors.email.message}<br/></ErrorMessage>
                            <Label>Message</Label>
                            <TextArea
                                name="message" 
                                ref={
                                    register({
                                        required: true
                                    })
                                }
                            />
                            <ErrorMessage>{errors.message && "Please enter a message"}<br/></ErrorMessage>
                            <SubmitButton type="submit">Submit</SubmitButton>
                        </ContactForm>
                    </ContactFormContainer>
                    <AppointmentsFeesInfoBox>
                        <AppointmentsFeesInfo>See <Link to='/appointments-fees'>Appointments & Fees</Link> for details and COVID protocols regarding your appointment.</AppointmentsFeesInfo>
                    </AppointmentsFeesInfoBox>
                </ContactPageContainer>
            </div>
        )
    } else if (notFound) {
        return <NotFound />
    } else {
        return (
            <div className="page">
                <Spinner />
            </div>
        )
    }
}

export default ContactUs