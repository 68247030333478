import styled from 'styled-components'
import { IoIosArrowForward } from 'react-icons/io'

export const FaqPageContainer = styled.div`
    margin: 0 20% 12% 20%;

    @media screen and (max-width: 768px) {
        margin: 0 10% 50px 10%;
    }
`;

export const FaqContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 2%;
`;

export const ArrowContainer = styled.div`
    display: flex;
    padding-right: 10px;
`;

export const Arrow = styled(IoIosArrowForward)`
    color: #A3DCBB;
    font-size: 24px;

    @media screen and (max-width: 479px){
        font-size: 18px;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;