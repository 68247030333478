import styled from 'styled-components'
import Button from 'react-bootstrap/Button'

const StyledButton = styled(Button)`{
    background-color: #4a825b; 
    border-color: #4a825b;
    font-weight: 600;

    &:hover {
        background-color: #995205;
        border-color: #995205;
    }
}`

export default StyledButton