import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Navbar, Footer, Logo } from './components'
import { apiEndpoint } from './prismic-configuration'
import { Home, OurTeam, Mission, Services, AppointmentsFees, Faq, ContactUs, NotFound } from './pages'

const App = () => {
  const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint)
  const repoName = repoNameArray[1]
  const [isHome, setIsHome] = useState(true)

  const scrollWidthOffset = (element, screen) => {
      const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = screen >= 479 ? -275 : -200; 
      console.log(screen, yCoordinate + yOffset, yOffset)
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  }

  return (
    <Fragment>
      <Helmet>
        <script async defer src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`} />
      </Helmet>
        <BrowserRouter>
          {!isHome && <Logo />}
          <Navbar scrollWidthOffset={scrollWidthOffset} isHome={isHome}/>
          <Switch>
            <Route exact path='/' render={(props) => <Home {...props} scrollWidthOffset={scrollWidthOffset} setIsHome={setIsHome} />} />
            <Route exact path='/our-team' render={(props) => <OurTeam {...props} setIsHome={setIsHome} />} />
            <Route exact path='/mission' render={(props) => <Mission {...props} setIsHome={setIsHome} />} />
            <Route exact path='/services' render={(props) => <Services {...props} setIsHome={setIsHome} />} />
            <Route exact path='/appointments-fees' render={(props) => <AppointmentsFees {...props} setIsHome={setIsHome} />} />
            <Route exact path='/faq' render={(props) => <Faq {...props} setIsHome={setIsHome} />} />
            <Route exact path='/contact-us' render={(props) => <ContactUs {...props} setIsHome={setIsHome} />} />
            <Route render={(props) => <NotFound {...props} setIsHome={setIsHome} />} />
          </Switch>
          {!isHome && <Footer />}
        </BrowserRouter>
    </Fragment>
  )
}

export default App
