import React, { useEffect } from 'react'
import {
    ImageContainer,
    Image,
    ImageIpad,
    ImageMobile,
    TextContainer,
    Title,
    Subtitle,
    LinksContainer,
    Leaf,
    LinkStyle,
    ServicesLinks,
    Contact,
} from '../styles/Home.js'


export default function Home({ setIsHome, scrollWidthOffset }) {
    const handleScroll = (element) => scrollWidthOffset(element, window.screen.width)

    useEffect(() => {
        setIsHome(true)
    }, [])

    return (
        <ImageContainer>
            <Image src="/assets/HomepageImage.jpg" alt="green DNA with leaves"/>
            <ImageIpad src="/assets/HomepageImageIpad.jpg" alt="green DNA with leaves"/>
            <ImageMobile src="/assets/HomepageImageMobile.jpg" alt="green DNA with leaves"/>
            <TextContainer>
                <Title>Complete Health and Healing</Title>
                <Subtitle>Life Enhancing Life Changing</Subtitle>
                <LinksContainer>
                    <ServicesLinks scroll={handleScroll} to='/services#applied-kinesiology'>
                        <Leaf />
                        <LinkStyle>Applied Kinesiology</LinkStyle>
                    </ServicesLinks>
                    <ServicesLinks scroll={handleScroll} to='/services#energy-healing'>
                        <Leaf />
                        <LinkStyle>Energy Healing</LinkStyle>
                    </ServicesLinks>
                    <ServicesLinks scroll={handleScroll} to='/services#holistic-nutrition'>
                        <Leaf />
                        <LinkStyle>Holistic Nutrition</LinkStyle>
                    </ServicesLinks>
                </LinksContainer>
                <Contact>
                    <a href='https://goo.gl/maps/QToUedHyjigZqTuh8' target='_blank'>157 Clawson St. Staten Island, NY 10306</a>
                    <br/><a href='tel:7189875454'>(718) 987-5454</a>
                    <br/><a href='mailto:Healthnhealing4you@gmail.com'>Healthnhealing4you@gmail.com</a>
                </Contact>
            </TextContainer>
        </ImageContainer>
    )
}
