import styled from 'styled-components'

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    background-color: #fff;
    top: 0;
    z-index: 10;
`;

export const StyledLogo = styled.img`
    height: 100%;
    cursor: pointer;

    @media screen and (max-width: 479px){
        width: 100%;
    }
`;