import React from 'react'
import PulseLoader from "react-spinners/PulseLoader"
import styled, { css } from 'styled-components'

const loadStyle = css`
    margin: 150px 0;
`

const Spinner = () => <PulseLoader color={'#A3DCBB'} css={loadStyle} size={25} />

export default Spinner