import styled from 'styled-components'

export const Heading = styled.h1`
    margin: 0;
    padding: 50px 0;
    position: fixed;
    z-index: 2;
    background-color: white;
    width: 100%;
    text-align: center;

    @media screen and (max-width: 479px){
        padding: 25px 0; 
    }
`;

export const AllServices = styled.div`
    margin-bottom: 125px;

    @media screen and (max-width: 479px){
        margin-bottom: 75px;
    }
`
export const ServiceContainer = styled.div`
    margin: 0 10%;
    position: relative;
    top: 150px;

    @media screen and (max-width: 479px){
        top: 100px;
    }
`;

export const SubHeading = styled.h2`
    font-style: italic;
    text-align: center;
`;

export const ImageContainer = styled.div`
    text-align: center;
`;

export const StyledImage = styled.img`
    width: 100%;
    border: solid 1px lightgray;
    margin-top: 10px;
    padding: 10px;
`;

export const ServicesTextContainer = styled.div`
    margin: 50px 0 75px 0;
    
    @media screen and (max-width: 479px) {
        margin: 25px 0 75px 0;
    }
`;

export const ServicesText = styled.div`
    margin: 25px 0;
`;