import React, { useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { client, linkResolver } from '../prismic-configuration'
import NotFound from './NotFound'
import { Content, Profile, ImgColumn, TextColumn, Name } from '../styles/OurTeam'
import { Divider, Spinner } from '../components'

const OurTeam = ({ setIsHome }) => {
    const [doc, setDocData] = useState(null)
    const [notFound, toggleNotFound] = useState(false)

    useEffect(() => {
        setIsHome(false)
        const fetchData = async () => {
            const result = await client.getByUID('our_team', 'our-team')

            if (result) {
                return setDocData(result)
            } else {
                console.warn('Page document not found. Make sure it exists in your Prismic repository')
                toggleNotFound(true)
            }
        }
        fetchData()
    }, [])
    
    if (doc) {
        return (
            <div className="page">
                <h1>{RichText.asText(doc.data.title)}</h1>
                <Content>
                    <Profile>
                        <ImgColumn>
                            <img src={doc.data.profile1.url} alt={doc.data.profile1.alt} />
                            <RichText render={doc.data.quote1} />
                        </ImgColumn>
                        <TextColumn>
                            <Name>{RichText.asText(doc.data.profile_name1)}</Name>
                            <RichText render={doc.data.bio1} />
                        </TextColumn>
                    </Profile>
                    <Divider></Divider>
                    <Profile reverse>
                        <ImgColumn>
                            <img src={doc.data.profile2.url} alt={doc.data.profile2.alt} />
                            <RichText render={doc.data.quote2} />
                        </ImgColumn>
                        <TextColumn>
                            <Name>{RichText.asText(doc.data.profile_name2)}</Name>
                            <RichText render={doc.data.bio2} />
                        </TextColumn>
                    </Profile>
                </Content>
            </div>
        )
    } else if (notFound) {
        return <NotFound />
    } else {
        return (
            <div className="page">
                <Spinner />
            </div>
        )
    }
}

export default OurTeam